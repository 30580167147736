import './base'
import { ProjectsPage } from 'discover'
import { createElement } from 'react'
import { renderWithLocale } from 'shared'

document.addEventListener('DOMContentLoaded', () => {
  const pageMountpoint = document.getElementById('page-mountpoint')
  if (pageMountpoint) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = pageMountpoint.getAttribute('data-json')!
    const page = createElement(ProjectsPage, JSON.parse(data))
    renderWithLocale(page, pageMountpoint)
  }
})
